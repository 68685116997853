import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../hooks/auth'
import CircularProgress from '@material-ui/core/CircularProgress'

import styled from 'styled-components'
import AppHeader from '../components/AppHeader/AppHeader'
import { Alert, AlertTitle } from '@material-ui/lab'
import Container from '@material-ui/core/Container'

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
`

const ContainerAlert = styled(Container)`
  margin-top: 1rem;
`

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, hasError, isLoading } = useAuth()

  if (isLoading)
    return (
      <Container>
        <Loader>
          <CircularProgress color="secondary" />
          <b>cargando</b>
        </Loader>
      </Container>
    )
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && !hasError ? (
          <>
            <AppHeader />
            <Component {...props} />
          </>
        ) : (
          <>
            {hasError ? (
              <>
                <AppHeader />
                <ContainerAlert>
                  <Alert severity="error">
                    <AlertTitle>Se produjo un error en el servicio</AlertTitle>
                    No se ha podido cargar la informacion del usuario.
                  </Alert>
                </ContainerAlert>
              </>
            ) : (
              <Redirect to="/" />
            )}
          </>
        )
      }
    />
  )
}

export default PrivateRoute
