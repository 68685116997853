import styled from 'styled-components'
import React from 'react'
import { Link } from 'react-router-dom'
import AppHeader from '../components/AppHeader/AppHeader'

const Container = styled.div`
  max-width: 1024px;
  margin: 1rem auto;
`

const NotFound = () => (
  <>
    <AppHeader />
    <Container>
      <h1>404 - Not Found!</h1>
      <Link to="/">Ir al inicio</Link>
    </Container>
  </>
)

export default NotFound
