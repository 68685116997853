import waitlist from '../reducers'
import React, { createContext, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import * as actions from '../constants'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const MainContext = createContext(null)

const MainProvider = ({ children, initData }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [state, dispatch] = useReducer(waitlist, initData)
  const [showSnack, onShowSnack] = useState({})

  const setInitialData = visits => {
    dispatch({ type: actions.SET_INITIAL_DATA, initialData: visits })
  }

  const addVisit = (visit, user) => {
    dispatch({
      type: actions.CREATE_WAITLIST_VISIT,
      visit: visit,
      user: user,
    })
  }
  const deleteVisit = visit => {
    dispatch({ type: actions.DELETE_WAITLIST_VISIT, item: visit })
  }

  const confirmVisit = visit => {
    dispatch({ type: actions.CONFIRM_WAITLIST_VISIT, item: visit })
  }

  const updateVisit = visit => {
    dispatch({ type: actions.UPDATE_WAITLIST_VISIT, customer: visit })
  }

  const editVisit = visit => {
    dispatch({ type: actions.EDIT_WAITLIST_VISIT, item: visit })
  }

  const notifyVisit = visit => {
    dispatch({ type: actions.NOTIFY_WAITLIST_GUEST, item: visit })
  }

  const store = {
    ...initData,
    ...state,
    currentCustomer: state.customer,
    addVisit,
    deleteVisit,
    confirmVisit,
    updateVisit,
    editVisit,
    notifyVisit,
    business: state.business,
    setInitialData,
    isMobile,
    onShowSnack,
    showSnack,
  }
  return <MainContext.Provider value={store}>{children}</MainContext.Provider>
}

MainProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initData: PropTypes.any.isRequired,
}

const useMainContext = () => {
  const context = React.useContext(MainContext)
  if (context === undefined) {
    throw new Error('useMainContext must be used within a MainProvider')
  }
  return context
}

export { useMainContext, MainProvider }
