import React from 'react'
import ElapsedTime from '../../components/ElapsedTime/ElapsedTime.js'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AccountCircle from '@material-ui/icons/AccountCircle'
import PeopleIcon from '@material-ui/icons/People'
import { getStyleIconParty } from '../../utils/helpers'
const ItemWrapper = styled.div`
  margin-bottom: 0.2rem;
  border-radius: 5px;
  background: ${({ active }) => (active ? '#fcffe9' : 'white')};
`

const MainRow = styled.div`
  font-size: 16px;
  text-align: left;
  position: relative;
  height: 75px;
  color: #555;

  display: flex;
  align-items: center;
  padding: 0 1rem;
`

const PartySize = styled.div`
  width: 40px;
  color: ${({ color }) => color};
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  b {
    margin-right: 5px;
  }
`

const Name = styled.div`
  width: 150px;
  display: flex;
  align-items: center;
  font-weight: 400;
  flex-grow: 1;
`

const YourPlace = styled.div`
  margin-left: 0.5rem;
`

const Waiting = styled.div`
  width: 70px;
  flex-shrink: 0;
  text-align: right;
`

const PendingItem = ({ item, index }) => {
  const isFirst = index + 1 === 1
  const active = item.active

  const colorParty = getStyleIconParty(item.partySize)

  return (
    <ItemWrapper active={active}>
      <MainRow>
        <PartySize color={colorParty}>
          <b>{item.partySize}</b>
          <PeopleIcon />
        </PartySize>
        <Name>
          {active ? (
            <>
              <AccountCircle color="action" />
              <YourPlace>{isFirst ? 'Es tu turno!' : 'Tu posición'}</YourPlace>
            </>
          ) : (
            <div>{`${item.name.slice(0, 3)}*****`}</div>
          )}
        </Name>
        <Waiting>
          <ElapsedTime
            value={item.createdAt}
            compareTo="now"
            max={item.quoted}
          />
        </Waiting>
        <Waiting>
          <ElapsedTime value={item.quoted} />
        </Waiting>
      </MainRow>
    </ItemWrapper>
  )
}

PendingItem.defaultProps = {
  item: {},
}

PendingItem.propTypes = {
  item: PropTypes.object,
}

export default PendingItem
