import React, { useEffect, useState } from 'react'
import PendingItem from './PendingItem'
import WaitlistStats from '../../components/WaitlistStats/WaitlistStats.js'
import WaitlistHeader from '../../components/WaitlistHeader/WaitlistHeader.js'

import styled from 'styled-components'
import { Alert, AlertTitle } from '@material-ui/lab'
import CircularProgress from '@material-ui/core/CircularProgress'

// FIREBASE
import firebase from '../../services/Firebase'

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TitleBusiness = styled.div`
  font-weight: 500;
  font-size: 24px;
  margin-top: 1rem;
`

const PendingList = props => {
  const [pendings, setPendings] = useState([])
  const [waitTimes, setWaitTimes] = useState([])
  const [businessName, setBusinessName] = useState('')
  const [hasError, setHasError] = useState(false)
  const [isLoading, setLoading] = useState(true)
  // const idBusiness = props.match.params.idBusiness
  const idClient = props.match.params.idClient

  const pendingMapper = (list = [], idClient) => {
    return list.map(visit => ({
      createdAt: visit.createdAt,
      id: visit.id,
      note: visit.note,
      quoted: visit.quoted,
      name: `${visit.name.slice(0, 3)}*****`,
      tags: visit.tags,
      partySize: visit.partySize,
      active: Boolean(visit.phone === idClient),
    }))
  }

  const getRealTimePendings = async () => {
    const docRef = firebase.db
      .collection('business')
      .where('allPhones', 'array-contains', idClient)

    docRef.onSnapshot(
      docSnapshot => {
        if (!docSnapshot.empty) {
          const items = []
          docSnapshot.forEach(doc => items.push(doc.data()))
          // Get first business for the phone
          const firstItem = items[0]
          const secureData = {
            ...firstItem,
            list: pendingMapper(firstItem.list, idClient),
          }
          if (!secureData.list) return
          setPendings(secureData.list)
          setWaitTimes(secureData.waitTimes)
          setBusinessName(secureData.business.businessName)
        }
        setLoading(false)
        setHasError(false)
      },
      err => {
        setHasError(true)
        setLoading(false)
      }
    )
  }

  useEffect(() => {
    getRealTimePendings()
  }, [])

  return (
    <>
      {isLoading && (
        <Loader>
          <CircularProgress color="secondary" />
          <b>cargando</b>
        </Loader>
      )}

      {hasError ? (
        <Alert severity="error">
          <AlertTitle>Se produjo un error al cargar esta lista</AlertTitle>
          <span>Esta URL de fila no es válida o no existe.</span>
        </Alert>
      ) : (
        <>
          {pendings.length > 0 ? (
            <>
              <TitleBusiness> {businessName} </TitleBusiness>
              <Header>
                <WaitlistStats waitTimes={waitTimes} />
              </Header>

              <WaitlistHeader />
              <div component="ul">
                {pendings.map((item, index) => (
                  <PendingItem
                    key={item.id}
                    item={item}
                    currentId={idClient}
                    index={index}
                  />
                ))}
              </div>
            </>
          ) : (
            <Alert severity="info">
              <AlertTitle>No estas en ninguna fila</AlertTitle>
              <span>{`En estos momentos no poseemos registros de una fila para el nº ${idClient}`}</span>
            </Alert>
          )}
        </>
      )}
    </>
  )
}

export default PendingList
