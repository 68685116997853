export const colors = {
  green: '#1bb596',
  yellow: '#ECBC43',
  blue: '#3f51b5',
  red: '#c51162',
}

export const STATUS = {
  confirmed: 'confirmado',
  deleted: 'eliminado',
}

const PartyColors = {
  orange: '#ffa49f',
  emerald: '#4db3c5',
  violet: '#b271f5',
  blue: '#6979e8',
}

export const getStyleIconParty = size => {
  switch (size) {
    case 1:
      return PartyColors.orange
    case 2:
      return PartyColors.orange
    case 3:
      return PartyColors.emerald
    case 4:
      return PartyColors.emerald
    case 5:
      return PartyColors.violet
    case 6:
      return PartyColors.violet
    default:
      return PartyColors.blue
  }
}
