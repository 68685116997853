import React, { useState } from 'react'

import PartySizePicker from '../PartySizePicker/PartySizePicker.js'
import TagsPicker from '../TagsPicker/TagsPicker.js'
import IncrementInput from '../IncrementInput/IncrementInput.js'
import Button from '../Button/Button.js'
import PropTypes from 'prop-types'

import Validator from '../../services/Validator'
import { get } from 'lodash'
import { InputAdornment, TextField } from '@material-ui/core'
import styled from 'styled-components'

const Row = styled.div`
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
`

const TwoColumn = styled.div`
  display: flex;
  width: 100%;

  > *:first-child {
    margin-right: 0.5rem;
    flex-grow: 1;
  }

  > *:last-child {
    margin-left: 0.5rem;
    flex-grow: 1;
  }
`

const QuotedRow = styled(Row)`
  font-size: 14px;
  color: #888;
`

const validator = new Validator()
const modelValidation = {
  name: ['isNotEmpty'],
  partySize: ['isInt', 'isMin:1'],
}

const CustomerForm = props => {
  const [values, setValues] = useState({
    name: props.name,
    phone: props.phone,
    partySize: props.partySize,
    note: props.note,
    tags: props.tags,
    quoted: props.quoted,
  })
  const [validations, setValidations] = useState()
  const [isLoading, setLoading] = useState(false)

  const handleChange = event => {
    const nam = event.target.name
    const val = event.target.value
    setValues(prevState => ({ ...prevState, [nam]: val }))
  }

  const handleChangeQuoted = value => {
    setValues(prevState => ({ ...prevState, quoted: value }))
  }

  const handleChangePartySize = value => {
    setValues(prevState => ({ ...prevState, partySize: value }))
  }

  const handleChangeTags = value => {
    setValues(prevState => ({ ...prevState, tags: value }))
  }

  const getModel = () => {
    return {
      name: values.name,
      phone: values.phone,
      partySize: values.partySize,
      note: values.note,
      tags: values.tags,
      quoted: values.quoted,
    }
  }

  const save = async () => {
    setLoading(true)
    const validations = validator.validateModel(values, modelValidation)
    if (validations.isValid) {
      await props.onSave(values)
      setLoading(false)
      setValidations(null)
      reset()
    } else {
      setValidations(validations)
      setLoading(false)
    }
  }

  const reset = () => {
    setValues(getModel())
  }

  return (
    <form>
      <Row>
        <TwoColumn>
          <TextField
            name="name"
            label="Nombre"
            value={values.name}
            placeholder="Ej: Juan Perez"
            fullWidth
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={get(validations, 'name.isNotEmpty') === false}
            helperText="Ingrese un nombre"
          />

          <TextField
            name="phone"
            type="number"
            label="Celular"
            value={values.phone}
            placeholder="99999999"
            fullWidth
            onChange={handleChange}
            onInput={e => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 9)
            }}
            s
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+56</InputAdornment>
              ),
              maxLength: 8,
            }}
          />
        </TwoColumn>
      </Row>
      <Row>
        <PartySizePicker
          name="partySize"
          onChange={handleChangePartySize}
          value={values.partySize}
        />
      </Row>
      <hr />
      <QuotedRow>
        <IncrementInput
          name="quoted"
          value={values.quoted}
          onChange={handleChangeQuoted}
          step={5}
          label="Tiempo estimado"
          suffix="min"
          allowZero={true}
        />
      </QuotedRow>
      <hr />
      <Row>
        <TextField
          name="note"
          value={values.note}
          label="Notas"
          multiline
          rows={3}
          onChange={handleChange}
          placeholder="Ej: cumpleaños"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Row>
      <Row>
        <TagsPicker
          name="tags"
          onChange={handleChangeTags}
          value={values.tags}
        />
      </Row>
      <Button theme="primary" onClick={save} fullWidth disabled={isLoading}>
        Guardar
      </Button>
    </form>
  )
}

CustomerForm.defaultProps = {
  name: '',
  phone: '',
  partySize: 1,
  note: '',
  tags: [],
  quoted: 0,
}

CustomerForm.propTypes = {
  name: PropTypes.string,
  partySize: PropTypes.number,
  phone: PropTypes.string,
  note: PropTypes.string,
  tags: PropTypes.array,
  quoted: PropTypes.number,
  onSave: PropTypes.func.isRequired,
}

export default CustomerForm
