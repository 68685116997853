import React from 'react'

import Duration from '../Duration/Duration.js'
import styled from 'styled-components'
import PeopleIcon from '@material-ui/icons/People'
import { makeStyles } from '@material-ui/core/styles'
import { useMainContext } from '../../provider/MainProvider.js'

const useStyles = makeStyles(theme => ({
  stats: {
    margin: theme.spacing(1),
  },
}))

const WaitTimesList = styled.ul`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  overflow: auto;
  li:nth-child(1) {
    background: linear-gradient(to top, #feb584, #ffa49f);
  }
  li:nth-child(2) {
    background: linear-gradient(to top, #65d6ea, #4db3c5);
  }
  li:nth-child(3) {
    background: linear-gradient(to top, #c592f9, #b271f5);
  }
  li:nth-child(4) {
    background: linear-gradient(to top, #839aea, #6979e8);
  }
`

const WaitTimeItem = styled.li`
  text-align: center;
  flex-basis: 0;
  flex-grow: 1;
  color: white;
  padding: 0 1rem;
  background: white;
  border-radius: 10px;
  padding: 1rem;
  min-width: ${({ mobile }) => (mobile ? '80px' : '100px')};
`

const IconContainer = styled.div`
  height: 12px;
  width: 12px;
  margin-right: 1rem;
  position: relative;
`

const Label = styled.div`
  white-space: nowrap;
  font-size: 21px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
`

const WaitTimesBySize = ({ label, value }) => {
  return (
    <div>
      <Label>
        <IconContainer>
          <PeopleIcon />
        </IconContainer>
        <span>{label}</span>
      </Label>
      <Duration value={value} unit="min" size="large" />
    </div>
  )
}

const WaitlistStats = ({ waitTimes }) => {
  const classes = useStyles()
  const { isMobile } = useMainContext()
  return (
    <WaitTimesList>
      {waitTimes.map(metric => (
        <WaitTimeItem
          key={metric.label}
          className={classes.stats}
          mobile={isMobile}
        >
          <WaitTimesBySize value={metric.value} label={metric.label} />
        </WaitTimeItem>
      ))}
    </WaitTimesList>
  )
}

export default WaitlistStats
