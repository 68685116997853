import React from 'react'
import CustomerForm from '../../components/CustomerForm/CustomerForm.js'
import * as routes from '../../routes'
import PropTypes from 'prop-types'
import { useMainContext } from '../../provider/MainProvider'
import Card from '../../common/card'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import { Grid } from '@material-ui/core'

const Customer = styled.div`
  margin-top: 2rem;
  max-width: 450px;
`

const EditVisit = props => {
  const { updateVisit, currentCustomer } = useMainContext()

  const save = customer => {
    const customerData = Object.assign({}, currentCustomer, customer)
    updateVisit(customerData)
    props.history.push(routes.DASHBOARD)
  }

  return (
    <Container fixed>
      <Grid item md={4}>
        <Customer>
          <Card>
            <CustomerForm onSave={save} {...currentCustomer} />
          </Card>
        </Customer>
      </Grid>
    </Container>
  )
}

EditVisit.propTypes = {
  customer: PropTypes.object,
  update: PropTypes.func.isRequired,
}

export default EditVisit
