import React, { useEffect, useState } from 'react'
import { getRecords } from '../../services/api'
import Container from '@material-ui/core/Container'
import { Alert, AlertTitle } from '@material-ui/lab'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import styled from 'styled-components'
import { colors, STATUS } from '../../utils/helpers'

const Title = styled.h1`
  margin: 2rem 0;
`

const RowEmpty = styled.p`
  width: 100%;
  background: white;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BadgeStatus = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.5rem;
  color: white;
  border-radius: 40px;
  width: 80px;
  background: ${({ status }) =>
    status === 'confirmed' ? colors.green : colors.red};
`

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#999',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
})

const HistorialCustomers = () => {
  const classes = useStyles()
  const [hasError, setError] = useState(false)
  const [rows, setRows] = useState([])

  const getListRecords = async () => {
    try {
      const data = await getRecords()
      if (!data) return
      setRows(data.records)
      setError(false)
    } catch (error) {
      setError(true)
    }
  }

  const getFormatDate = unixTime => {
    const date = new Date(unixTime).toLocaleDateString('es-CL')
    return date
  }

  const getFormatTime = unixTime => {
    const hour = new Date(unixTime).getHours()
    const minutes = new Date(unixTime).getMinutes()
    return `${hour}:${minutes}`
  }

  useEffect(() => {
    getListRecords()
  }, [])

  return (
    <Container fixed>
      {hasError && (
        <Alert severity="error">
          <AlertTitle>Se produjo un error</AlertTitle>
          No se ha podido cargar el historial de clientes.
        </Alert>
      )}

      <Title>Historial de clientes </Title>
      <TableContainer>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Nombre</StyledTableCell>
              <StyledTableCell align="left">Nº personas</StyledTableCell>
              <StyledTableCell align="left">Nota</StyledTableCell>
              <StyledTableCell align="left">Tiempo de espera</StyledTableCell>
              <StyledTableCell align="left">Fecha</StyledTableCell>
              <StyledTableCell align="left">Estado</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map(row => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="left">{row.partySize}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.note || '-'}
                </StyledTableCell>
                <StyledTableCell align="left">{row.quoted}</StyledTableCell>
                <StyledTableCell align="left">
                  {getFormatDate(row.createdAt)} -{' '}
                  {getFormatTime(row.createdAt)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <BadgeStatus status={row.status}>
                    {STATUS[row.status] || ''}
                  </BadgeStatus>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!rows.length && <RowEmpty>Aun no tienes nada en tu historial</RowEmpty>}
    </Container>
  )
}

export default HistorialCustomers
