import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { checkAuth, uiConfig, firebaseApp } from '../../services/Firebase'
import styled from 'styled-components'
import imageQeue from '../../assets/image-qeue-4.png'
import step1Image from '../../assets/step1.png'
import step2Image from '../../assets/step2.png'
import step3Image from '../../assets/step3.png'
import AppHeader from '../../components/AppHeader/AppHeader'
import { StyledFirebaseAuth } from 'react-firebaseui'
import Container from '@material-ui/core/Container'
import demoScreenImage1 from '../../assets/screen1.png'
import demoScreenImage2 from '../../assets/screen2.png'
import demoMessageImage from '../../assets/mobile4.jpg'

const DemoScreen = styled.img`
  box-shadow: 1px 1px 5px #333;
  height: 500px;
  width: auto;
  @media (max-width: 769px) {
    margin-bottom: 2rem;
  }
`

const WrapperScreens = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;

  @media (max-width: 769px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

const QeueImage = styled.img`
  top: 120px;
  right: 0;
  position: absolute;
  @media (max-width: 769px) {
    padding: 0;
    top: 0;
    width: 100%;
    position: relative;
  }
`

const Title = styled.h1`
  font-size: 36px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 10px;
  color: white;
`

const WrapperText = styled.div`
  margin-top: 120px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 769px) {
    margin-top: 80px;
  }
`

const Subtitle = styled.h2`
  font-size: 21px;
  color: white;
  font-weight: 200;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 15px;
`

const StepList = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  @media (max-width: 769px) {
    flex-direction: column;
    margin-top: 2rem;
  }
  p,
  b {
    color: #333e50;
  }
  li {
    flex: 1;
    padding: 1rem;
  }
`

const Step = styled.img`
  height: 70px;
`

const TitleStep = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  b {
    margin-left: 15px;
    font-family: 'Montserrat', sans-serif;
    font-size: 21px;
    @media (max-width: 769px) {
      font-size: 28px;
    }
  }
`
const ContainerBanner = styled.div`
  width: 100%;
  height: 100%;
  background: url('/bg-1.jpg') no-repeat;
  min-height: 500px;
`

const RowStep = styled.li`
  margin-bottom: 15px;
  p {
    font-size: 1.2rem;
  }
`

const Footer = styled.footer`
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  background: #333e50;

  a {
    color: white;
  }
`

const HomeContainer = styled(Container)`
  padding-top: 1rem;
`

const Home = () => {
  const [isAuthenticated, setAuthenticated] = useState(false)

  const checkIsLogged = async () => {
    const isAuthenticated = await checkAuth()
    setAuthenticated(isAuthenticated)
  }

  useEffect(() => {
    checkIsLogged()
  }, [])

  return (
    <>
      {!isAuthenticated ? (
        <>
          <AppHeader />
          <ContainerBanner>
            {/* <BannerImage src={background} alt="" /> */}

            <HomeContainer>
              <WrapperText>
                <Title>No mas filas físicas!</Title>
                <Subtitle>
                  Ingresa a tus clientes en una fila virtual y podrán ver en
                  tiempo real su posición.
                </Subtitle>
                <StyledFirebaseAuth
                  uiConfig={uiConfig}
                  firebaseAuth={firebaseApp.auth()}
                />
              </WrapperText>

              <QeueImage src={imageQeue} alt="" />
            </HomeContainer>
          </ContainerBanner>

          <HomeContainer>
            <StepList>
              <RowStep>
                <TitleStep>
                  <Step src={step1Image} alt="" />
                  <b>Paso 1</b>
                </TitleStep>
                <p>Ingresa las preferencias de tu cliente.</p>
              </RowStep>
              <RowStep>
                <TitleStep>
                  <Step src={step2Image} alt="" />
                  <b>Paso 2</b>
                </TitleStep>
                <p>
                  El recibirá un link donde podrá ver como avanza la fila en
                  tiempo real.
                </p>
              </RowStep>
              <RowStep>
                <TitleStep>
                  <Step src={step3Image} alt="" />
                  <b>Paso 3</b>
                </TitleStep>
                <p>Listo! Se acercará a tu local cuando ya sea su turno.</p>
              </RowStep>
            </StepList>

            <WrapperScreens>
              <DemoScreen src={demoScreenImage2} alt="" />
              <DemoScreen src={demoMessageImage} alt="" />
              <DemoScreen src={demoScreenImage1} alt="" />
            </WrapperScreens>
          </HomeContainer>
          <Footer>
            <a href="mailto:contacto@bukeat.cl">
              Más información: contacto@bukeat.cl
            </a>
          </Footer>
        </>
      ) : (
        <Redirect to="/dashboard" />
      )}
    </>
  )
}

export default Home
