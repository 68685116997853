// import * as firebase from 'firebase/app'
import firebase from 'firebase/app'
import * as firebaseui from 'firebaseui'
import 'firebase/analytics'
// Required for side-effects
require('firebase/firestore')

export const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyBzCexg5hPt-NaZcwFHBATTVPXwaO6hYsI',
  authDomain: 'waitlist-644fe.firebaseapp.com',
  databaseURL: 'https://waitlist-644fe.firebaseio.com',
  projectId: 'waitlist-644fe',
  storageBucket: 'waitlist-644fe.appspot.com',
  messagingSenderId: '475102463528',
  appId: '1:475102463528:web:eb7d3f15a41745223ed71d',
  measurementId: 'G-YH3G199YBX',
})

export const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // signInSuccessUrl: '/signedIn',
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      firebase.auth().setPersistence('session')

      window.location.assign('/dashboard')
      return false
    },
    // uiShown: onReady,
  },
}

const db = firebase.firestore()
const analytics = firebase.analytics()
export const checkAuth = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(async _user => {
      if (_user) {
        resolve(_user)
      } else {
        // No user is signed in.
        resolve(null)
      }
    })
  })
}

export const logout = () => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful and redirect.
        window.location.assign('/')
        return resolve(true)
      })
      .catch(error => {
        // Sign-out error.
        reject(error)
      })
  })
}

const all = {
  firebase,
  firebaseui,
  firebaseApp,
  db,
  logout,
  analytics,
}
export default all
