import React from 'react'
import AdminList from './AdminList'
import Container from '@material-ui/core/Container'
import { useAuth } from '../../hooks/auth'

const Admin = props => {
  const { user } = useAuth()
  const validAdmin = user
    ? user.idBusiness === 'LDTQUIuRnWaV2JUiC6xbZefgx6v2'
    : false

  return (
    <>
      <Container fixed>
        {validAdmin ? <AdminList {...props} /> : <p>No autorizado</p>}
      </Container>
    </>
  )
}

export default Admin
