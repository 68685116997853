import styled from 'styled-components'
import React from 'react'

const CardWrapper = styled.div`
  border: 1px solid #d0d2d8;
  background: white;
  padding: 1rem;
  border-radius: 5px;

  .card-header {
    margin-bottom: 1rem;
  }
`

const Card = ({ children }) => {
  return <CardWrapper>{children}</CardWrapper>
}

export default Card
