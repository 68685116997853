import * as actions from '../constants'
import WaitTimes from '../services/WaitTimes.js'
import Tags from '../services/Tags.js'
import { updateVisitBusiness, recordVisit } from '../services/api'

const waitlist = (state, action) => {
  switch (action.type) {
    case actions.SET_INITIAL_DATA: {
      if (!action.initialData.list) return state
      const waitTimes = new WaitTimes({
        list: action.initialData.list,
        waitTimes: state.waitTimes,
      }).calculate()

      return { ...state, ...action.initialData, waitTimes }
    }

    case actions.CONFIRM_WAITLIST_VISIT: {
      const list = state.list.filter(item => item !== action.item)
      const waits = new WaitTimes({
        list,
        waitTimes: state.waitTimes,
      })
      const waitTimes = waits.calculate()
      const updated = { ...state, list, waitTimes }
      updateVisitBusiness(updated)
      recordVisit({ visit: { ...action.item, status: 'confirmed' } })
      return updated
    }

    case actions.DELETE_WAITLIST_VISIT: {
      const list = state.list.filter(item => item !== action.item)
      const waits = new WaitTimes({
        list,
        waitTimes: state.waitTimes,
      })
      const waitTimes = waits.calculate()
      const updated = { ...state, list, waitTimes }
      updateVisitBusiness(updated)
      recordVisit({ visit: { ...action.item, status: 'deleted' } })
      return updated
    }

    case actions.EDIT_WAITLIST_VISIT:
      return { ...state, customer: action.item }

    case actions.UPDATE_WAITLIST_VISIT: {
      const list = state.list.map((visit, index) => {
        return visit.id === action.customer.id ? action.customer : visit
      })

      const waitTimes = new WaitTimes({
        list,
        waitTimes: state.waitTimes,
      }).calculate()

      const { tagsUsed, tags } = new Tags({
        state,
        customer: action.customer,
      }).calculate()

      const updated = { ...state, list, tagsUsed, tags, waitTimes }
      updateVisitBusiness(updated)
      return updated
    }

    case actions.CREATE_WAITLIST_VISIT: {
      const customer = {
        id: Date.now(),
        createdAt: Date.now(),
        ...action.visit,
      }
      const newList = [...state.list, customer]
      const updatedVisits = { ...state, list: newList }

      return updatedVisits
    }

    default:
      return state
  }
}

export default waitlist
