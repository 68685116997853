import React from 'react'
import { NavLink } from 'react-router-dom'
import * as routes from '../../routes.js'

import logo from '../../assets/logo4.png'
import styled from 'styled-components'
import { logout } from '../../services/Firebase'
import { useAuth } from '../../hooks/auth.js'
import Hidden from '@material-ui/core/Hidden'
import Container from '@material-ui/core/Container'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useMainContext } from '../../provider/MainProvider'

const useStyles = makeStyles(theme => ({
  button: {
    color: '#fff',
    fontSize: '2rem',
  },
}))

const Account = styled.div`
  display: flex;
  align-items: center;
  color: white;
  border-radius: 50px;
  padding: 5px 10px;
  font-size: 16px;
`

const AccountMobile = styled(Account)`
  border-radius: 0;
  background: #777;
`

const ListItem = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  > li:first-child {
    margin-right: 1rem;
  }
`

const BackGround = styled.div`
  width: 100%;
  z-index: 1;
  padding: 1rem 0;
  background: linear-gradient(to left, #ff8e97, #8b34cc);
  /* background: linear-gradient(to left, #222, #333); */
`

const Logo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  color: white;
  img {
    height: 50px;
  }
`

const Flex = styled.nav`
  display: flex;
  justify-content: space-between;
`

const AppHeader = ({ history }) => {
  const classes = useStyles()

  const { isMobile } = useMainContext()

  const { isAuthenticated, user } = useAuth()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigateToHistorial = () => {
    history.push('/historial')
    handleClose()
  }

  const navigateToDashboard = () => {
    history.push('/dashboard')
    handleClose()
  }

  return (
    <>
      <BackGround mobile={isMobile}>
        <Container fixed>
          <Flex>
            <Logo>
              <NavLink exact to={routes.DASHBOARD}>
                <img src={logo} alt="Logo" />
              </NavLink>
            </Logo>
            {isAuthenticated && (
              <ListItem>
                <li>
                  <Hidden smDown>
                    <Account>
                      {user && (
                        <>
                          {user.businessName}{' '}
                          <small> &nbsp; - &nbsp; {user.email}</small>
                        </>
                      )}
                    </Account>
                  </Hidden>
                </li>
                <li>
                  <IconButton onClick={handleMenu}>
                    <AccountCircle className={classes.button} />
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={navigateToDashboard}>Mi panel</MenuItem>
                    <MenuItem onClick={navigateToHistorial}>
                      Mi historial
                    </MenuItem>
                    <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
                  </Menu>
                </li>
              </ListItem>
            )}
          </Flex>
        </Container>
      </BackGround>
      {user && (
        <Hidden mdUp>
          <AccountMobile>
            <Container fixed>
              {user && (
                <>
                  {user.businessName}{' '}
                  <small> &nbsp; - &nbsp; {user.email}</small>
                </>
              )}
            </Container>
          </AccountMobile>
        </Hidden>
      )}
    </>
  )
}

export default withRouter(AppHeader)
