import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Switch from '@material-ui/core/Switch'
import { updateUser } from '../../services/api'

const Row = styled.section`
  display: flex;
  box-shadow: 0;
  border: 0;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  text-align: left;
  position: relative;
  padding-bottom: 1rem;
  height: 75px;
  width: 100%;
`

const Container = styled.div`
  margin: 1rem auto;
  background: white;
`

const MainRow = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0 1rem;
  width: 100%;
`

const Column = styled.div`
  flex-basis: 170px;
  flex-grow: 1;
  font-size: 14px;
`

const Option = styled.div`
  width: 70px;
  flex-shrink: 0;
  text-align: right;
`

const AdminItem = ({ item, currentId, index }) => {
  const [isChecked, setIsChecked] = useState(item.active)

  const handleChange = event => {
    setIsChecked(event.target.checked)
    updateUser(item.idBusiness, { active: event.target.checked })
  }
  return (
    <Container>
      <Row>
        <MainRow>
          <Column>
            {item.businessName} <small>({item.nameUser})</small>
          </Column>
          <Column>{item.businessType}</Column>
          <Column>{item.email}</Column>
          <Column>{item.location}</Column>
          <Option>
            <Switch
              checked={isChecked}
              onChange={handleChange}
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Option>
        </MainRow>
      </Row>
    </Container>
  )
}

AdminItem.defaultProps = {
  item: {},
}

AdminItem.propTypes = {
  item: PropTypes.object,
}

export default AdminItem
