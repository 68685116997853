import React, { useEffect, useRef, useState } from 'react'
import Waitlist from '../../components/Waitlist/Waitlist.js'
import { useMainContext } from '../../provider/MainProvider'
import { getVisits } from '../../services/api'
import FormBusiness from '../FormBusiness'
import { useAuth } from '../../hooks/auth'
import Container from '@material-ui/core/Container'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

const Dashboard = ({ history }) => {
  const { list, waitTimes, setInitialData, showSnack } = useMainContext()
  const { userExists, isActive } = useAuth()
  const isUserPending = Boolean(userExists && !isActive)
  const showDashboard = userExists && isActive
  const [open, setOpen] = useState(false)
  const lastAdded = useRef(showSnack)

  useEffect(() => {
    if (!showSnack.message || showSnack === lastAdded.current) return
    handleClick()
  }, [showSnack])

  const getListVisits = async () => {
    if (list.length || !showDashboard) return
    const data = await getVisits()
    if (!data.list) return
    setInitialData(data)
  }

  useEffect(() => {
    getListVisits()
  }, [])

  const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <Container fixed>
      {showDashboard ? (
        <Waitlist history={history} list={list} waitTimes={waitTimes} />
      ) : (
        <FormBusiness isUserPending={isUserPending} />
      )}
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {showSnack.message}
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default Dashboard
