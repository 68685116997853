import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import * as routes from '../../routes.js'
import GlobalStyle from '../../theme/globalStyles'

// PAGES
import Dashboard from '../../pages/Dashboard/Dashboard.js'
import CreateVisit from '../../pages/CreateVisit/CreateVisit.js'
import EditVisit from '../../pages/EditVisit/EditVisit'
import PendingCustomers from '../../pages/PendingCustomers/PendingCustomers'
import HistorialCustomers from '../../pages/HistorialCustomers'
import Home from '../../pages/Home'
import Admin from '../../pages/Admin'
import NotFound from '../../pages/NotFound'

import reducer from '../../reducers'

import { MainProvider } from '../../provider/MainProvider'
import PrivateRoute from '../../pages/PrivateRoute'

// AUTH CONTEXT
import { AuthContext } from '../../hooks/auth'
import { checkAuth } from '../../services/Firebase'
import { getUserDetail } from '../../services/api'
import ErrorBoundary from '../../pages/ErrorBoundary.js'

const defaultData = {
  list: [],
  tags: ['Interior', 'Terraza', 'Silla bebe', 'Vegetariano', 'Alergico', 'VIP'],
  waitTimes: [
    { label: '1 - 2', min: 1, max: 2, value: 0 },
    { label: '3 - 4', min: 3, max: 4, value: 0 },
    { label: '5 - 6', min: 5, max: 6, value: 0 },
    { label: '7+', min: 7, value: 0 },
  ],
  // FIXME: replace customer with dinamic selection of current customer in reducer
  customer: {},
}

const store = createStore(reducer, defaultData)

const App = () => {
  const [authData, setAuthData] = useState({
    isAuthenticated: false,
    isActive: false,
    userExist: false,
    isLoading: true,
  })

  const checkIsLogged = async () => {
    try {
      const isAuthenticated = await checkAuth()
      if (isAuthenticated) {
        const user = await getUserDetail()
        const userExists = Boolean(user)
        const authData = {
          userExists,
          isActive: user ? user.active : false,
          isAuthenticated: Boolean(isAuthenticated),
          user,
          isLoading: false,
        }
        setAuthData(authData)
        // console.log('authData', authData)
      } else {
        setAuthData(prevState => ({ ...prevState, isLoading: false }))
      }
    } catch (e) {
      setAuthData(prevState => ({
        ...prevState,
        isLoading: false,
        hasError: true,
        error: e,
      }))
    }
  }

  useEffect(() => {
    checkIsLogged()
  }, [])

  return (
    <ErrorBoundary>
      <GlobalStyle />
      <MainProvider initData={defaultData}>
        <AuthContext.Provider value={authData}>
          <Provider store={store}>
            <div className="App">
              <Router>
                <Switch>
                  <Route exact path={routes.HOME} component={Home} />
                  <PrivateRoute
                    exact
                    path={routes.DASHBOARD}
                    component={Dashboard}
                  />
                  <PrivateRoute
                    path={routes.CREATE_VISIT}
                    component={CreateVisit}
                  />
                  <PrivateRoute
                    path={routes.EDIT_VISIT}
                    component={EditVisit}
                  />
                  <PrivateRoute
                    path={routes.HISTORIAL}
                    component={HistorialCustomers}
                  />
                  <Route
                    path={routes.PENDING_CUSTOMERS}
                    component={PendingCustomers}
                  />
                  <PrivateRoute exact path={routes.ADMIN} component={Admin} />
                  <Route component={NotFound} />
                </Switch>
              </Router>
            </div>
          </Provider>
        </AuthContext.Provider>
      </MainProvider>
    </ErrorBoundary>
  )
}

export default App
