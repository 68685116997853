import React from 'react'
import styles from './SearchError.module.scss'
import searchLoupe from '../../assets/search.svg'
import ReactSVG from 'react-svg'
import PropTypes from 'prop-types'

const EmptyResults = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Tu lista de espera esta vacia.</h1>
    </div>
  )
}

const NotFound = props => {
  return (
    <div className={styles.container}>
      <ReactSVG src={searchLoupe} svgClassName={styles.icon} />
      <h1 className={styles.title}>
        No se encontraron resultados para {props.query}.
      </h1>
    </div>
  )
}

NotFound.defaultProps = {
  query: '',
}

NotFound.propTypes = {
  query: PropTypes.string,
}

const SearchError = props => {
  if (props.query) {
    return <NotFound query={props.query} />
  } else {
    return <EmptyResults />
  }
}

SearchError.defaultProps = {
  query: '',
}

SearchError.propTypes = {
  query: PropTypes.string,
}

export default SearchError
