import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SearchIcon from '@material-ui/icons/Search'

const Input = styled.input`
  width: 100%;
  outline: none;
  box-shadow: none;
  padding: 8px 20px 8px 40px;
  background-color: rgba(255, 255, 255, 0.98);
  border-radius: 5px;
  border-color: white;
  border: none;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    opacity: ${({ focus }) => focus && '0'};
  }
`

const IconContainer = styled.div`
  position: absolute;
  top: 7px;
  left: 10px;
  color: #999;
`

const WrapperSearchInput = styled.div`
  position: relative;
  width: 100%;
`

const SearchInput = props => {
  const [isFocused, setFocus] = useState()
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current.focus()
  }, [props.autofocus])

  const onFocus = () => {
    setFocus(true)
    props.onFocusChange(true)
  }

  const onBlur = () => {
    setFocus(false)
    props.onFocusChange(false)
  }

  const onChange = e => {
    const value = e.target.value
    props.onChange(value)
  }

  return (
    <WrapperSearchInput>
      <IconContainer>
        <SearchIcon />
      </IconContainer>
      <Input
        focus={isFocused}
        ref={inputRef}
        value={props.value || ''}
        type="search"
        placeholder={props.placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </WrapperSearchInput>
  )
}

SearchInput.defaultProps = {
  placeholder: 'Buscar...',
  autofocus: false,
  value: '',
  onChange: () => {},
  onFocusChange: () => {},
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  autofocus: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func,
}

export default SearchInput
