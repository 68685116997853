import React from 'react'
import PendingList from './PendingList'
import AppHeader from '../../components/AppHeader/AppHeader'
import Container from '@material-ui/core/Container'

const PendingCustomers = props => {
  return (
    <>
      <AppHeader />
      <Container fixed>
        <PendingList {...props} />
      </Container>
    </>
  )
}

export default PendingCustomers
