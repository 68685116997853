import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import styled from 'styled-components'

const HeadingWrapper = styled.div`
  display: flex;
  color: #555;
  /* position: sticky;
  top: 0; */
  font-size: 12px;
  letter-spacing: 1px;
  padding: 0.5rem 1rem;
  z-index: 1;
`

const Name = styled.div`
  width: 150px;
  flex-grow: 1;
`

const PartySize = styled.div`
  flex-shrink: 0;
  width: 40px;
  flex-grow: 0;
`

const Tags = styled.div`
  flex-shrink: 0;
  width: 200px;
  flex-grow: 2;
`

const Waiting = styled.div`
  flex-shrink: 0;
  width: 70px;
  text-align: right;
`

const Quoted = styled.div`
  flex-shrink: 0;
  width: 70px;
  text-align: right;
`

const WaitlistHeader = () => {
  return (
    <>
      <Hidden smDown>
        <HeadingWrapper>
          <PartySize>#</PartySize>
          <Name>Nombre</Name>
          <Tags>Tags</Tags>
          <Waiting>Esperando</Waiting>
          <Quoted>Estimado</Quoted>
        </HeadingWrapper>
      </Hidden>
      <Hidden smUp>
        <HeadingWrapper>
          <Name>Nombre</Name>
          <Waiting>Esperando</Waiting>
          <Quoted>Estimado</Quoted>
        </HeadingWrapper>
      </Hidden>
    </>
  )
}

export default WaitlistHeader
