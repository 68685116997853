import React from 'react'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Check from '@material-ui/icons/Check'
import Edit from '@material-ui/icons/Edit'

import DeleteIcon from '@material-ui/icons/Delete'
import styled from 'styled-components'
import Hidden from '@material-ui/core/Hidden'
import ButtonCustom from '../../common/Button'
import PhoneIcon from '@material-ui/icons/Phone'
import Tag from '../Tag/Tag'
import { useMainContext } from '../../provider/MainProvider'

import { withRouter } from 'react-router-dom'
import * as routes from '../../routes'

const CircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  color: #ffffff;
  background: ${({ bgcolor }) => bgcolor};
  transition: all 0.2s ease-in;
  border: none;
  &:hover {
    opacity: 0.8;
  }
`

const ButtonList = styled.div`
  display: flex;
  button {
    margin-right: 1rem;
  }
`

const ButtonListDesktop = styled(ButtonList)`
  margin-left: 1rem;
`

const Label = styled.p`
  font-weight: bold;
  margin-bottom: 0.5rem;
`

const ListContentMobile = styled.ul`
  color: #333;
  margin-left: 1rem;
  li {
    margin-bottom: 1.2rem;
  }
  li:last-child {
    margin-bottom: 0;
  }
`

const colors = {
  green: '#1bb596',
  yellow: '#ECBC43',
  blue: '#3f51b5',
  red: '#c51162',
}

const TagList = ({ item, max = 3 }) => {
  if (!item.tags || !item.tags.length) {
    return <p>-</p>
  }

  const hasMoreTags = item.tags.length > max

  const tags = item.tags.slice(0, max).map(tag => (
    <Tag key={tag} clickable={false} theme="compact">
      {tag}
    </Tag>
  ))

  return (
    <div>
      {tags} {hasMoreTags && <span>+</span>}
    </div>
  )
}

const WaitlistItemOptions = ({ item, history }) => {
  const { deleteVisit, editVisit, confirmVisit } = useMainContext()

  const onCompleteItem = () => {
    confirmVisit(item)
  }

  const onEditItem = () => {
    editVisit(item)
    history.push(routes.EDIT_VISIT)
  }

  const onDeleteItem = () => {
    deleteVisit(item)
  }

  const callToNumber = () => {
    document.location.href = `tel:+56${item.phone}`
  }
  return (
    <div>
      <div>
        <Hidden smDown>
          <ButtonListDesktop>
            <ButtonCustom
              label="Listo"
              color={colors.green}
              startIcon={<CheckCircle />}
              onClick={onCompleteItem}
            ></ButtonCustom>

            {item.phone && (
              <ButtonCustom
                label="Llamar"
                color={colors.yellow}
                startIcon={<PhoneIcon />}
                onClick={callToNumber}
              ></ButtonCustom>
            )}

            <ButtonCustom
              label="Editar"
              color={colors.blue}
              startIcon={<Edit />}
              onClick={onEditItem}
            ></ButtonCustom>

            <ButtonCustom
              label="Eliminar"
              color={colors.red}
              startIcon={<DeleteIcon />}
              onClick={onDeleteItem}
            ></ButtonCustom>
          </ButtonListDesktop>
        </Hidden>

        <Hidden mdUp>
          <ListContentMobile>
            <li>
              <Label>Tags</Label>
              <TagList item={item} />
            </li>
            <li>
              <Label>Notas</Label>
              <p>{item.note || '-'}</p>
            </li>

            <li>
              <Label>Acciones</Label>
              <ButtonList>
                <CircleButton bgcolor={colors.green} onClick={onCompleteItem}>
                  <Check />
                </CircleButton>
                <CircleButton bgcolor={colors.yellow} onClick={callToNumber}>
                  <PhoneIcon />
                </CircleButton>
                <CircleButton bgcolor={colors.blue} onClick={onEditItem}>
                  <Edit />
                </CircleButton>
                <CircleButton bgcolor={colors.red} onClick={onDeleteItem}>
                  <DeleteIcon />
                </CircleButton>
              </ButtonList>
            </li>
          </ListContentMobile>
        </Hidden>
      </div>
    </div>
  )
}

export default withRouter(WaitlistItemOptions)
