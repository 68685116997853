import React, { useState } from 'react'
import CustomerForm from '../../components/CustomerForm/CustomerForm.js'
import { DASHBOARD } from '../../routes.js'
import { useMainContext } from '../../provider/MainProvider'
import { useAuth } from '../../hooks/auth'
import Container from '@material-ui/core/Container'
import { Grid, makeStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { addVisitBusiness } from '../../services/api.js'

const useStyles = makeStyles({
  root: {
    padding: '1rem',
    marginTop: '2rem',
  },
})

const CreateVisit = props => {
  const { addVisit, onShowSnack } = useMainContext()
  const { user } = useAuth()
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const save = async visit => {
    try {
      await addVisit(visit, user)
      const customer = {
        id: Date.now(),
        createdAt: Date.now(),
        ...visit,
      }
      await addVisitBusiness({ visit: customer })
      onShowSnack({
        message: `Se ha enviado un SMS a ${visit.name}`,
        type: 'success',
      })
      props.history.push(DASHBOARD)
    } catch (e) {
      handleClick()
    }
  }

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  return (
    <Container fixed>
      <Grid item md={4}>
        <Card className={classes.root}>
          <CustomerForm onSave={save} />
        </Card>
      </Grid>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Ha ocurrido un error al ingresar nuevo cliente
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default CreateVisit
