import React from 'react'
import styled from 'styled-components'

const ButtonStyle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ color }) => color && color};
  color: white;
  border: none;
  padding: 0.3rem 1rem;
  font-size: 1rem;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background: ${({ color }) => color && 'rgba(color, 1.2)'};
    box-shadow: '0 0 0 0.2rem rgba(0,123,255,.5)';
  }
  p {
    font-size: 14px;
    margin-left: ${({ hasIcon }) => hasIcon && '0.5rem'};
  }
`

const ButtonCustom = ({ startIcon, color, className, label, ...props }) => {
  return (
    <ButtonStyle
      {...props}
      color={color}
      className={className}
      hasIcon={Boolean(startIcon)}
    >
      {startIcon}
      <p>{label}</p>
    </ButtonStyle>
  )
}

export default ButtonCustom
