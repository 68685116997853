import React, { useEffect, useState } from 'react'
import Button from '../Button/Button.js'
import PropTypes from 'prop-types'

import styled from 'styled-components'

const ButtonbarStyle = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  overflow: hidden;

  > *:first-child {
    border-left: 1px solid transparent;
  }
`

const Buttonbar = props => {
  const [selected, setSelected] = useState(props.value)

  useEffect(() => {
    setSelected(props.value)
  }, [props.value])

  const click = (button, index) => {
    return e => {
      setSelected(index)
      button.props.onClick(e, button.props)
    }
  }

  return (
    <ButtonbarStyle>
      {props.children.map((button, index) => {
        const theme = selected === index ? 'buttonbar-selected' : 'buttonbar'
        return (
          <Button key={index} theme={theme} onClick={click(button, index)}>
            {button.props.children}
          </Button>
        )
      })}
    </ButtonbarStyle>
  )
}

Buttonbar.defaultProps = {
  updateSelected: true,
  value: '',
}

Buttonbar.propTypes = {
  updateSelected: PropTypes.bool,
  value: PropTypes.string,
}

export default Buttonbar
