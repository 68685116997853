import React, { useState } from 'react'
import WaitlistItemOptions from '../WaitlistItemOptions/WaitlistItemOptions.js'
import ElapsedTime from '../ElapsedTime/ElapsedTime.js'
import Tag from '../Tag/Tag.js'
import PropTypes from 'prop-types'
import PeopleIcon from '@material-ui/icons/People'
import styled from 'styled-components'
import Hidden from '@material-ui/core/Hidden'
import { getStyleIconParty } from '../../utils/helpers.js'

const ItemWrapper = styled.div`
  margin-bottom: 0.2rem;
  border-radius: 5px;
  background-color: ${({ opened }) => opened && 'white'};
`

const AccordionHeader = styled.button`
  background: white;
  border: 0;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  text-align: left;
  position: relative;
  height: 75px;
  color: #555;

  display: flex;
  align-items: center;
  padding: 0 1rem;

  &:hover,
  &:focus {
    outline: 0;
  }
`

const PartySize = styled.div`
  width: 40px;
  color: ${({ color }) => color};
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  b {
    margin-right: 5px;
  }
`

const Name = styled.div`
  width: 150px;
  font-size: 1rem;
  font-weight: 400;
  flex-grow: 1;
`

const Tags = styled.div`
  flex-shrink: 0;
  width: 200px;
  flex-grow: 2;
`

const Notes = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Quoted = styled.div`
  width: 70px;
  flex-shrink: 0;
  text-align: right;
`

const AccordionContent = styled.div`
  padding: 1rem 0;
  border-top: 1px solid #d2d2d4;
  margin-bottom: 1rem;
`

const WaitlistItem = props => {
  const [showOptions, setShowOptions] = useState()

  const toggleOptions = () => {
    setShowOptions(prevState => !prevState)
  }

  const renderTags = (max = 10) => {
    if (!props.item.tags || !props.item.tags.length) {
      return
    }

    const hasMoreTags = props.item.tags.length > max

    const tags = props.item.tags.slice(0, max).map(tag => (
      <Tag key={tag} clickable={false} theme="compact">
        {tag}
      </Tag>
    ))

    return (
      <div>
        {tags} {hasMoreTags && <span>+</span>}
      </div>
    )
  }

  const renderOptions = () => {
    if (!showOptions) {
      return
    }

    return (
      <AccordionContent>
        <WaitlistItemOptions item={props.item} />
      </AccordionContent>
    )
  }

  const colorParty = getStyleIconParty(props.item.partySize)

  return (
    <ItemWrapper opened={showOptions}>
      <AccordionHeader onClick={toggleOptions}>
        <PartySize color={colorParty}>
          <b>{props.item.partySize}</b>
          <PeopleIcon />
        </PartySize>
        <Name>
          <div>{props.item.name}</div>
          <Hidden smDown>
            <Notes>{props.item.note}</Notes>{' '}
          </Hidden>
        </Name>
        <Hidden smDown>
          <Tags>{renderTags(3)}</Tags>
        </Hidden>
        <Quoted>
          <ElapsedTime
            value={props.item.createdAt}
            compareTo="now"
            max={props.item.quoted}
          />
        </Quoted>
        <Quoted>
          <ElapsedTime value={props.item.quoted} />
        </Quoted>
      </AccordionHeader>

      {renderOptions()}
    </ItemWrapper>
  )
}

WaitlistItem.defaultProps = {
  item: {},
}

WaitlistItem.propTypes = {
  item: PropTypes.object,
}

export default WaitlistItem
