import firebase from 'firebase/app'

export const API_URL = process.env.REACT_APP_PROD_API_URL
// export const API_URL = 'http://localhost:5000/waitlist-644fe/us-central1/api'

export const getTokenCurrentUser = async () => {
  const token = await firebase.auth().currentUser.getIdToken()
  return token
}
export const getCurrentUser = async () => {
  const user = await firebase.auth().currentUser
  return user
}

export const getVisits = async () => {
  const user = await getCurrentUser()
  const token = await getTokenCurrentUser()
  return fetch(`${API_URL}/users/visits/${user.uid}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'access-token': token,
    },
  })
    .then(res => res.json())
    .catch(error => {
      console.error(error)
    })
}

export const getUserDetail = async () => {
  const user = await getCurrentUser()
  const token = await getTokenCurrentUser()

  return fetch(`${API_URL}/users/${user.uid}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'access-token': token,
    },
  })
    .then(response => {
      if (!response.ok) throw Error('error')
      if (response.status === 204) return null
      return response.json()
    })
    .catch(error => {
      throw error
    })
}

export const getAllUsers = async () => {
  const token = await getTokenCurrentUser()
  return fetch(`${API_URL}/users`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'access-token': token,
    },
  })
    .then(res => res.json())
    .catch(error => {
      console.error(error)
      return null
    })
}

export const updateVisitBusiness = async updated => {
  const token = await getTokenCurrentUser()
  return fetch(`${API_URL}/users/visits`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'access-token': token,
    },
    body: JSON.stringify(updated),
  })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      console.error(error)
      return null
    })
}

export const createUser = async newUser => {
  const token = await getTokenCurrentUser()
  return fetch(`${API_URL}/users`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'access-token': token,
    },
    body: JSON.stringify(newUser),
  })
    .then(response => {
      if (!response.ok) throw Error('error createUser')
      return response.json()
    })
    .catch(error => {
      throw error
    })
}

export const updateUser = async (uid, data) => {
  const token = await getTokenCurrentUser()
  fetch(`${API_URL}/users/${uid}/update`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'access-token': token,
    },
    body: JSON.stringify(data),
  })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      console.error(error)
      return null
    })
}

export const recordVisit = async data => {
  const token = await getTokenCurrentUser()
  fetch(`${API_URL}/records`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'access-token': token,
    },
    body: JSON.stringify(data),
  })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      console.error(error)
      return null
    })
}

export const getRecords = async () => {
  const token = await getTokenCurrentUser()
  return fetch(`${API_URL}/records`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'access-token': token,
    },
  })
    .then(response => {
      if (!response.ok) throw Error('error get Records')
      if (response.status === 204) return null
      return response.json()
    })
    .catch(error => {
      throw error
    })
}

export const addVisitBusiness = async dataObj => {
  const token = await getTokenCurrentUser()
  return fetch(`${API_URL}/users/createVisit`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'access-token': token,
    },
    body: JSON.stringify(dataObj),
  })
    .then(response => {
      if (!response.ok) throw Error('error add visit')
      if (response.status === 204) return null
      return response.json()
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
