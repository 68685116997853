import React, { useState } from 'react'
import WaitlistItem from '../WaitlistItem/WaitlistItem.js'
import styles from './Waitlist.module.scss'

import SearchInput from '../SearchInput/SearchInput.js'
import Button from '../Button/Button.js'
import SearchError from '../SearchError/SearchError.js'
import WaitlistStats from '../WaitlistStats/WaitlistStats.js'
import WaitlistHeader from '../WaitlistHeader/WaitlistHeader.js'
import * as routes from '../../routes.js'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import styled from 'styled-components'
import { useMainContext } from '../../provider/MainProvider.js'

const SearchInputContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  > *:last-child {
    margin-left: 1rem;
  }
`

const HeaderOptions = styled.div`
  display: flex;
  flex-direction: ${({ mobile }) => (mobile ? 'column-reverse' : 'row')};
  justify-content: space-between;
`

const TotalWaiting = styled.p`
  color: #999;
  margin: 1rem 0;
`

const Waitlist = props => {
  const [state, setState] = useState({ query: null })

  const { waitTimes, isMobile } = useMainContext()
  const transitionConfig = {
    transitionName: {
      enter: styles.animateEnter,
      enterActive: styles.animateEnterActive,
      leave: styles.animateLeave,
      leaveActive: styles.animateLeaveActive,
    },
    transitionEnterTimeout: 500,
    transitionLeaveTimeout: 300,
  }

  const onAdd = () => {
    props.history.push(routes.CREATE_VISIT)
  }

  const onSearch = searchQuery => {
    const query = searchQuery.toLowerCase()
    setState({
      query,
      list: getFilteredList(query),
    })
  }

  const getFilteredList = query => {
    let list
    if (query) {
      list = props.list.filter(guest => {
        return guest.name.toLowerCase().includes(query)
      })
    } else {
      list = props.list
    }
    return list
  }

  const filteredList = getFilteredList(state.query)
  return (
    <>
      <HeaderOptions mobile={isMobile}>
        <SearchInputContent>
          <SearchInput
            onChange={onSearch}
            value={state.query}
            placeholder="Buscar..."
          />
          <Button onClick={onAdd} theme="primary">
            Agregar
          </Button>
        </SearchInputContent>

        <WaitlistStats waitTimes={waitTimes} />
      </HeaderOptions>

      {filteredList.length > 0 && (
        <>
          <TotalWaiting>Total en espera: {filteredList.length}</TotalWaiting>
          <WaitlistHeader />
        </>
      )}
      <ReactCSSTransitionGroup
        component="ul"
        className={styles.list}
        {...transitionConfig}
      >
        {filteredList.map(item => (
          <WaitlistItem key={item.id} item={item} />
        ))}
      </ReactCSSTransitionGroup>
      {!filteredList.length && <SearchError query={state.query} />}
    </>
  )
}

export default Waitlist
