import React, { useState } from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Card from '../common/card'
import Button from '../components/Button/Button'
import { createUser } from '../services/api'
import { Alert, AlertTitle } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      marginBottom: '2rem',
    },
  },
}))

const initialValues = {
  rut: '',
  name: '',
  type: '',
  location: '',
}

const WrapperForm = styled.div`
  max-width: 450px;
  margin-top: 1rem;
`

const Title = styled.h2`
  margin-bottom: 1rem;
`

const AlertWrapper = styled.div`
  margin: 1rem 0;
`

const FormBusiness = ({ isUserPending, userError }) => {
  const [formData, setFormData] = useState(initialValues)
  const [hasError, setHasError] = useState(userError)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleChange = event => {
    const nam = event.target.name
    const val = event.target.value
    setFormData(prevState => ({ ...prevState, [nam]: val }))
  }

  const onSubmitForm = async () => {
    try {
      const { name, type, location, phone, rut } = formData
      const newUSer = {
        businessName: name,
        businessType: type,
        location: location,
        phone: phone,
        rut,
      }
      await createUser(newUSer)
      setIsSubmitted(true)
    } catch (e) {
      setHasError(true)
    }
  }

  const classes = useStyles()

  const errorMessage = userError
    ? 'No se han podido cargar los datos del usuario'
    : 'Se ha producido un error al enviar los datos'
  return (
    <>
      {!isSubmitted && !hasError ? (
        <>
          {isUserPending ? (
            <AlertWrapper>
              <Alert severity="info">
                <AlertTitle>Solicitud pendiente de aprobación</AlertTitle>
                En pocas horas estaras habilitado para utilizar la aplicación y
                entregar a tus clientes una mejor experiencia.
              </Alert>
            </AlertWrapper>
          ) : (
            <WrapperForm>
              <Card>
                <Title>Ingrese los datos de su negocio</Title>
                <form className={classes.root} noValidate autoComplete="off">
                  <TextField
                    name="rut"
                    label="Rut del negocio"
                    placeholder="12.345.678-1"
                    fullWidth
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={initialValues.rut}
                  />

                  <TextField
                    name="name"
                    label="Nombre del negocio"
                    placeholder="Restaurant el pochito"
                    fullWidth
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={initialValues.name}
                  />

                  <TextField
                    name="type"
                    label="Giro comercial"
                    placeholder="Ej: Comida al paso, restaurant, etc"
                    fullWidth
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={initialValues.type}
                  />
                  <TextField
                    name="location"
                    label="Dirección completa"
                    style={{ marginBottom: '2rem' }}
                    placeholder="Av Providencia 123, Santiago"
                    fullWidth
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={initialValues.location}
                  />

                  <TextField
                    name="phone"
                    label="Telefono contacto"
                    style={{ marginBottom: '2rem' }}
                    placeholder="+569 12345678"
                    fullWidth
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={initialValues.phone}
                  />
                </form>
                <Button theme="primary" onClick={onSubmitForm}>
                  Guardar
                </Button>
              </Card>
            </WrapperForm>
          )}
        </>
      ) : null}

      <AlertWrapper>
        {isSubmitted && (
          <Alert severity="success">
            <AlertTitle>Solicitud enviada</AlertTitle>
            Espera mientras validamos tus datos, en breve podrás utilizar la
            aplicación
          </Alert>
        )}

        {(hasError || userError) && (
          <Alert severity="error">
            <AlertTitle>Se produjo un error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
      </AlertWrapper>
    </>
  )
}

export default FormBusiness
