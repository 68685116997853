import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/App/App.js'
// import reportWebVitals from './reportWebVitals'

const init = () => {
  ReactDOM.render(<App />, document.getElementById('root'))
}

init()
