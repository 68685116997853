import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ButtonStyle = styled.button`
  padding: 0.2rem 1rem;
  border: 1px solid #b534e2;
  border-radius: 5px;
  font-size: 16px;
  color: #b534e2;
  position: relative;
  cursor: pointer;

  width: ${({ fullWidth }) => fullWidth && '100%'};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #b534e2;
    border-radius: 3px;
    opacity: 0;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:focus {
    outline: none;
    &::before {
      opacity: 1;
    }
  }

  &.primary {
    min-width: 100px;
    height: 37px;
    background-color: #b534e2;
    color: white;
  }

  &.page-header {
    min-width: 140px;
    border-radius: 15px;
    height: 35px;
    background-color: transparent;
    cursor: pointer;
    border-color: #fff;
    color: #fff;
    border-width: 2px;

    &::before {
      border-radius: 12px;
      border-color: #fff;
    }
  }

  &.tertiary {
    border-color: #767676;
    color: #767676;

    &::before {
      border-color: #767676;
    }
  }

  &.icon {
    background-color: transparent;
    border-color: transparent;
    padding: 0;
    height: 60px;
    width: 60px;
    svg {
      fill: #888;
    }

    &:hover,
    &:focus {
      svg {
        fill: #b534e2;
      }
    }

    &::before {
      border-color: transparent;
    }
  }

  &.buttonbar,
  &.buttonbar-selected {
    border: 0;
    border-radius: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    padding: 0.5rem 1.5rem;
    color: rgba(0, 0, 0, 0.85);

    &::before {
      display: none;
    }
  }

  &.buttonbar:hover,
  &.buttonbar-selected {
    background-color: #777;
    color: #fff;
  }
`

const Button = props => {
  const click = e => {
    if (props.onClick) {
      props.onClick(e, props)
    }
  }

  return (
    <ButtonStyle
      onClick={click}
      className={props.theme}
      disabled={props.disabled}
      type={'button'}
      fullWidth={props.fullWidth}
    >
      {props.children}
    </ButtonStyle>
  )
}

export default Button

Button.defaultProps = {
  theme: 'primary',
  disabled: false,
  type: 'button',
  fullWidth: false,
}

Button.propTypes = {
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
}
