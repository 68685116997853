import React, { useState } from 'react'
import styles from './PartySizePicker.module.scss'
import Button from '../Button/Button.js'
import IncrementInput from '../IncrementInput/IncrementInput.js'
import Buttonbar from '../Buttonbar/Buttonbar.js'
import PropTypes from 'prop-types'

const PartySizePicker = props => {
  const [value, setValue] = useState(props.value)

  const incrementChange = value => {
    didChange(value)
  }

  const didChange = value => {
    setValue(value)
    props.onChange(value)
  }

  const shortcutClicked = (e, buttonProps) => {
    setValue(buttonProps.data)
    props.onChange(buttonProps.data)
  }

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <IncrementInput
          value={value}
          onChange={incrementChange}
          label="Cantidad de personas"
        />
      </div>
      <div className={styles.shortcuts}>
        <Buttonbar value={value - 1}>
          <Button onClick={shortcutClicked} data={1}>
            1
          </Button>
          <Button onClick={shortcutClicked} data={2}>
            2
          </Button>
          <Button onClick={shortcutClicked} data={3}>
            3
          </Button>
          <Button onClick={shortcutClicked} data={4}>
            4
          </Button>
          <Button onClick={shortcutClicked} data={5}>
            5
          </Button>
          <Button onClick={shortcutClicked} data={6}>
            6
          </Button>
        </Buttonbar>
      </div>
    </div>
  )
}

PartySizePicker.defaultProps = {
  value: 1,
  onChange: () => {},
}

PartySizePicker.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

export default PartySizePicker
