import React, { useEffect, useState } from 'react'
import AdminItem from './AdminItem'
import styled from 'styled-components'
import { getAllUsers } from '../../services/api'
import AdminHeader from './AdminHeader'

const WrapperAdmin = styled.div`
  margin: 1rem auto;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const AdminList = props => {
  const currentId = props.match.params.id
  const [list, setList] = useState([])

  useEffect(() => {
    const getUsers = async () => {
      try {
        const users = await getAllUsers()
        setList(users)
      } catch (e) {
        console.log('ERROR', e)
      }
    }

    getUsers()
  }, [])

  return (
    <WrapperAdmin>
      <Header>
        <h3>Administrador de usuarios</h3>
      </Header>
      {list.length > 0 && <AdminHeader />}
      <div component="ul">
        {list.map((item, index) => (
          <AdminItem
            key={item.id}
            item={item}
            currentId={currentId}
            index={index}
          />
        ))}
      </div>
    </WrapperAdmin>
  )
}

export default AdminList
