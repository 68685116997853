import React from 'react'
import styled from 'styled-components'

const Heading = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
  font-size: 12px;
  letter-spacing: 1px;
  padding: 0.5rem 1rem;
  z-index: 1;
`

const Column = styled.div`
  flex-basis: 170px;
  flex-grow: 1;
`
const Option = styled.div`
  width: 70px;
  flex-shrink: 0;
  text-align: right;
`

const AdminHeader = () => {
  return (
    <Heading>
      <Column>Nombre</Column>
      <Column>Tipo</Column>
      <Column>Email</Column>
      <Column>Dirección</Column>
      <Option>Activo</Option>
    </Heading>
  )
}

export default AdminHeader
