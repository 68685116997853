import styled from 'styled-components'
import React from 'react'
import errorImage from '../assets/error.png'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
`

const Container = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`

const Image = styled.img`
  height: 180px;
`

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
    return { hasError: true }
  }

  //   componentDidCatch(error, errorInfo) {
  //     // También puedes registrar el error en un servicio de reporte de errores
  //     logErrorToMyService(error, errorInfo)
  //   }

  render() {
    if (this.state.hasError) {
      // Puedes renderizar cualquier interfaz de repuesto
      return (
        <Container>
          <Wrapper>
            <Image src={errorImage} alt="error" />
            <h1>Ups! algo salió mal.</h1>
          </Wrapper>
        </Container>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
